import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Form from '../components/Form'

export const IndexPageTemplate = ({
  image,
  subheading,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0 margin-bottom-0"
      style={{
        minHeight: '1200px',
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'center',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <div style={{
          boxShadow:
          'rgba(0,0,0,0.5) 0.5rem 0px 0px, rgba(0,0,0,0.5) -0.5rem 0px 0px',
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
          padding: '10px',
          marginBottom: '10px',
          textAlign: 'center',
          maxWidth: '500px',
        }}>
          Guests, please book accomodations as soon as possible! Affordable rooms fill up quickly! If you would like help please feel free to <Link to='/contact'>contact us</Link>. Enter contact information on the <Link to='/contact'>contact page</Link> to get notified of changes to our itinerary.
        </div>
        <h1>
          <div
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                'rgba(0,0,0,0.5) 0.5rem 0px 0px, rgba(0,0,0,0.5) -0.5rem 0px 0px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: 'white',
              lineHeight: '1',
              padding: '20px',
              fontFamily: "'Dancing Script', cursive",
              textAlign: 'center'
            }}>
            {'Eileen Li'}
            <br />
            {'&'}
            <br />
            {'William Dickson'}
          </div>
        </h1>
        <div
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              'rgba(0,0,0,0.5) 0.5rem 0px 0px, rgba(0,0,0,0.5) -0.5rem 0px 0px',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <img width="500px" src="/img/us.png"></img>
        </div>
        <h3
          className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              'rgba(0,0,0,0.5) 0.5rem 0px 0px, rgba(0,0,0,0.5) -0.5rem 0px 0px',
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            lineHeight: '1.5',
            padding: '0.25em',
            fontFamily: "'Dancing Script', cursive",
            textAlign: 'center'
          }}
        >
          {'July 30, 2022 at 3:00PM'}
          <br />
          {'Block Island, Rhode Island'}
        </h3>
        <p
          className="is-size-6-mobile is-size-6-tablet is-size-5-widescreen"
          style={{
            boxShadow:
              'rgba(0,0,0,0.5) 0.5rem 0px 0px, rgba(0,0,0,0.5) -0.5rem 0px 0px',
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            lineHeight: '1.5',
            padding: '0.25em',
            fontFamily: "'Dancing Script', cursive",
            textAlign: 'center'
          }}
        >
          {'Dinner and Reception to follow'}
          <br />
          {'Dress code is Semi-Formal'}
        </p>
      </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
      }
    }
  }
`
